import getData from "@/__main__/get-data.mjs";
import CsgoStatsMaps from "@/data-models/csgo-stats-maps.mjs";
import CsgoStatsWeapons from "@/data-models/csgo-stats-weapons.mjs";
import { getStats } from "@/game-csgo/api.mjs";
import { getSearchParams } from "@/game-csgo/csgo-fetch-stats-data.mjs";

const networkOptions = {
  networkBackOffTime: 4 * 60 * 60 * 1000, // 4 hrs
};

function fetchData(_, searchParams) {
  const promises = [];

  const mapsParams = new URLSearchParams(getSearchParams(searchParams));
  const mapsKey = btoa(mapsParams.toString());
  const weaponsParams = new URLSearchParams(getSearchParams(searchParams));
  const weaponsKey = btoa(weaponsParams.toString());

  promises.push(
    getData(
      getStats(searchParams, "maps"),
      CsgoStatsMaps,
      ["csgo", "stats", "maps", mapsKey],
      networkOptions,
    ),
  );

  promises.push(
    getData(
      getStats(searchParams, "weapons"),
      CsgoStatsWeapons,
      ["csgo", "stats", "weapons", weaponsKey],
      networkOptions,
    ),
  );

  return Promise.all(promises);
}

export default fetchData;
